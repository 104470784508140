/** @format */

// export const routes = [
export const routes = [
    //
    {
        path: "/",
        name: "login",
        component: () => import("@/views/user/login.vue"),
    },
    {
        path: "/reg",
        name: "register",
        component: () => import("@/views/user/register.vue"),
    },
    {
        path: "/forget",
        name: "ForgetPass",
        component: () => import("@/views/user/forgetPass.vue"),
    },
    {
        // 布局
        path: "/layout",
        name: "layout",
        component: () => import("@/views/layout.vue"),
        redirect: "/home",
        children: [
            //首页
            {
                path: "/home",
                name: "home",
                component: () => import("@/views/home.vue"),
                meta: {
                    title: "Home",
                    mes: "Welcome to VDC platform",
                },
            },
            {
                path: "/usersetting",
                name: "usersetting",
                component: () => import("@/views/user/usersetting.vue"),
                meta: {
                    title: "个人设置",
                },
            },
            {
                path: "/vip",
                name: "vip",
                component: () => import("@/views/user/vip.vue"),
                meta: {
                    title: "会员中心",
                },
            },
            {
                path: "/noticeList",
                name: "noticeList",
                component: () => import("@/views/user/noticeList.vue"),
                meta: {
                    title: "公告列表",
                },
            },
            {
                path: "/agreement",
                name: "agreement",
                component: () => import("@/views/user/agreement.vue"),
                meta: {
                    title: "相关协议",
                },
            },

            // 教学案例云
            {
                path: "/caseCloud",
                name: "caseCloud",
                component: () => import("@/views/caseCloud/index.vue"),
                meta: {
                    title: "教学案例生成器(内测版)",
                },
            },

            // 数据报表
            {
                path: "/pbiPage",
                name: "pbiPage",
                component: () =>
                    import("@/views/dataVisualReport/index.vue"),
                meta: {
                    title: "数据报表",
                },
            },

            // 财务模型
            {
                path: "/financialModel",
                name: "financialModel",
                component: () =>
                    import("@/views/financialModel/index.vue"),
                meta: {
                    title: "财务模型",
                },
            },

            // 案例知识库
            {
                path: '/research_methods',
                name: 'research_methods',
                component: () =>
                    import('@/views/research_methods/index.vue'),
                meta: {
                    title: '案例知识库',
                }
            },

            // RAG
            {
                path: "/ragPage",
                name: "ragPage",
                component: () =>
                    import("@/views/usgRAG/index.vue"),
                meta: {
                    title: "RAG应用",
                },
            },
            
            // 文本分析
            {
                path: "/txtRetrieval",
                name: "txtRetrieval",
                component: () => import("@/views/txtRetrieval/index.vue"),
                meta: {
                    title: "文本分析",
                },
            },
            // AIG应用
            {
                path: "/usgAIG",
                name: "usgAIG",
                component: () => import("@/views/usgAIG/index.vue"),
                meta: {
                    title: "AIG应用",
                },
            },
            
        ],
    },
    {
        path: "/vipGo",
        name: "vipGo",
        component: () => import("@/views/vip_go.vue"),
    },
    {
        path: "/qa",
        name: "qa",
        component: () => import("@/views/test/qa.vue"),
    },
 // AIG应用
//  {
//     path: "/usgAIG",
//     name: "usgAIG",
//     component: () => import("@/views/layout.vue"),
//     redirect: "/smartFinancialAnalysis",
//     meta: {
//         title: "AIG应用",
//     },
//     children:[
//         {
//             path: "/smartFinancialAnalysis",
//             name: "smartFinancialAnalysis",
//             component: () =>
//                 import("@/views/usgAIG/iframePageFinance.vue"),
//             meta: {
//                 title: "智能财务分析",
//             },
//         },
//         {
//             path: "/smartPerformanceEvaluation",
//             name: "smartPerformanceEvaluation",
//             component: () =>
//                 import("@/views/usgAIG/iframePageFinance.vue"),
//             meta: {
//                 title: "智能业绩评价",
//             },
//         },
//         {
//             path: "/smartFinancialAudit",
//             name: "smartFinancialAudit",
//             component: () =>
//                 import("@/views/usgAIG/iframePageFinance.vue"),
//             meta: {
//                 title: "智能财务审计",
//             },
//         },
//         // 论文智能评阅
//         {
//             path: '/thesis',
//             name: 'thesis',
//             component: () =>
//                 import('@/views/usgAIG/thesis.vue'),
//             meta: {
//                 title: '论文智能评阅',
//             }
//         },
//     ]
// },

    // 公益课程
    {
        path: "/publicTemplate",
        name: "publicTemplate",
        component: () => import("@/views/layout.vue"),
        redirect: "/publicTemplateCourse",
        meta: {
            title: "公益课程平台",
        },
        children: [
            {
                path: "/publicTemplateCourse",
                name: "publicTemplateCourse",
                component: () =>
                    import("@/views/pubilcCourse/temp/temp_course.vue"),
                meta: {
                    title: "公益课程",
                },
            },
            {
                path: "/publicCourseAdd",
                name: "publicCourseAdd",
                component: () =>
                    import("@/views/pubilcCourse/temp/course_create.vue"),
                meta: {
                    title: "创建班级",
                },
            },
        ],
    },
    {
        path: "/publicCourse",
        name: "publicCourse",
        component: () => import("@/views/layout.vue"),
        redirect: "/publicCourseList",
        meta: {
            title: "我的班级",
        },
        children: [
            {
                path: "/publicCourseList",
                name: "publicCourseList",
                component: () =>
                    import("@/views/pubilcCourse/course/course_list.vue"),
                meta: {
                    title: "我的班级",
                },
            },
            {
                path: "/publicStudent",
                name: "publicStudent",
                component: () =>
                    import("@/views/pubilcCourse/course/student_list.vue"),
                meta: {
                    title: "学生管理",
                },
            },
            // {
            //     path: "/vdcAnalysis",
            //     name: "vdcAnalysis",
            //     component: () =>
            //         import("@/views/vdcCourse/course/student_analysis.vue"),
            //     meta: {
            //         title: "学情分析",
            //     },
            // },
            {
                path: "/publictask",
                name: "publictask",
                component: () =>
                    import("@/views/pubilcCourse/course/task_list.vue"),
                meta: {
                    title: "任务列表",
                },
            },
            {
                path: "/qaList",
                name: "qaList",
                component: () =>
                    import("@/views/vdcCourse/course/qa_list.vue"),
                meta: {
                    title: "常见问题列表",
                },
            },
        ],
    },
    {
        path: "/checkTxt",
        name: "checkTxt",
        component: () =>
            import("@/views/vdcCourse/course/check_txt.vue"),
        meta: {
            title: "说明文档",
        },
    },

    {
        path: "/publicReport",
        name: "publicReport",
        component: () =>
            import("@/views/pubilcCourse/report/layout_report.vue"),
        meta: {
            title: "公益课程报告",
        },
    },
    // PBI跳转
    {
        path: "/pbiView",
        name: "pbiView",
        component: () =>
            import("@/views/dataVisualReport/iframePage.vue"),
        meta: {
            title: "",
        },
    },
    // 文本分析跳转
    {
        path: "/txtRetrievalView",
        name: "txtRetrievalView",
        component: () => import("@/views/txtRetrieval/index.vue"),
        meta: {
            title: "",
        },
    },
    // 智能财务跳转
    {
        path: "/aiglView",
        name: "aiglView",
        component: () => import("@/views/usgAIG/authPage.vue"),
        meta: {
            title: "",
        },
    },


    // vcaseCourse 核心课程
    {
        path: "/VCaseTemplate",
        name: "VCaseTemplate",
        component: () => import("@/views/layout.vue"),
        redirect: "/vcaseTemplateCourse",
        meta: {
            title: "核心课程平台",
        },
        children: [
            {
                path: "/vcaseTemplateCourse",
                name: "vcaseTemplateCourse",
                component: () =>
                    import("@/views/vcaseCourse/temp/temp_course.vue"),
                meta: {
                    title: "核心专业课程",
                },
            },
            {
                path: "/vcaseCourseAdd",
                name: "vcaseCourseAdd",
                component: () =>
                    import("@/views/vcaseCourse/temp/course_create.vue"),
                meta: {
                    title: "创建班级",
                },
            },
        ],
    },
    {
        path: "/vcaseCourse",
        name: "vcaseCourse",
        component: () => import("@/views/layout.vue"),
        redirect: "/vcaseCourseList",
        meta: {
            title: "我的班级",
        },
        children: [
            {
                path: "/vcaseCourseList",
                name: "vcaseCourseList",
                component: () =>
                    import("@/views/vcaseCourse/course/course_list.vue"),
                meta: {
                    title: "我的班级",
                },
            },
            {
                path: "/vcaseCheck",
                name: "vcaseCheck",
                component: () =>
                    import("@/views/vcaseCourse/course/course_check.vue"),
                meta: {
                    title: "考核",
                },
            },
            {
                path: "/vcaseStudent",
                name: "vcaseStudent",
                component: () =>
                    import("@/views/vcaseCourse/course/student_list.vue"),
                meta: {
                    title: "学生管理",
                },
            },
            {
                path: "/vcaseAnalysis",
                name: "vcaseAnalysis",
                component: () =>
                    import("@/views/vcaseCourse/course/student_analysis.vue"),
                meta: {
                    title: "学情分析",
                },
            },
            {
                path: "/vcasetask",
                name: "vcasetask",
                component: () =>
                    import("@/views/vcaseCourse/course/task_list.vue"),
                meta: {
                    title: "任务列表",
                },
            },
        ],
    },
    {
        path: "/bookkeeping",
        name: "bookkeeping",
        component: () =>
            import("@/views/vcaseCourse/report/book_keeping.vue"),
        meta: {
            title: "记账凭证",
        },
    },
    {
        path: "/kaoshi",
        name: "kaoshi",
        component: () =>
            import("@/views/vcaseCourse/report/kao_shi.vue"),
        meta: {
            title: "考试模块",
        },
    },
    {
        path: "/sandTable",
        name: "sandTable",
        component: () =>
            import("@/views/vcaseCourse/report/sand_table.vue"),
        meta: {
            title: "沙盘",
        },
    },
    {
        path: "/vcaseReport",
        name: "vcaseReport",
        component: () =>
            import("@/views/vcaseCourse/report/layout_report.vue"),
        meta: {
            title: "vcase报告",
        },
    },
    {
        path: "/mapSandTable",
        name: "mapSandTable",
        component: () => import("@/views/vcaseCourse/report/map_sand_table.vue"),
        meta: {
            title: "",
        },
    },


    // vdcCourse 数智课程
    {
        path: "/vdcTemplate",
        name: "vdcTemplate",
        component: () => import("@/views/layout.vue"),
        redirect: "/vdcTemplateCourse",
        meta: {
            title: "数智课程平台",
        },
        children: [
            {
                path: "/vdcTemplateCourse",
                name: "vdcTemplateCourse",
                component: () =>
                    import("@/views/vdcCourse/temp/temp_course.vue"),
                meta: {
                    title: "数智课程",
                },
            },
            {
                path: "/vdcCourseAdd",
                name: "vdcCourseAdd",
                component: () =>
                    import("@/views/vdcCourse/temp/course_create.vue"),
                meta: {
                    title: "创建班级",
                },
            },
        ],
    },
    {
        path: "/vdcCourse",
        name: "vdcCourse",
        component: () => import("@/views/layout.vue"),
        redirect: "/vdcCourseList",
        meta: {
            title: "我的班级",
        },
        children: [
            {
                path: "/vdcCourseList",
                name: "vdcCourseList",
                component: () =>
                    import("@/views/vdcCourse/course/course_list.vue"),
                meta: {
                    title: "我的班级",
                },
            },
            {
                path: "/vdcCheck",
                name: "vdcCheck",
                component: () =>
                    import("@/views/vdcCourse/course/course_check.vue"),
                meta: {
                    title: "考核",
                },
            },
            {
                path: "/vdcStudent",
                name: "vdcStudent",
                component: () =>
                    import("@/views/vdcCourse/course/student_list.vue"),
                meta: {
                    title: "学生管理",
                },
            },
            {
                path: "/vdcAnalysis",
                name: "vdcAnalysis",
                component: () =>
                    import("@/views/vdcCourse/course/student_analysis.vue"),
                meta: {
                    title: "学情分析",
                },
            },
            {
                path: "/vdctask",
                name: "vdctask",
                component: () =>
                    import("@/views/vdcCourse/course/task_list.vue"),
                meta: {
                    title: "任务列表",
                },
            },
            {
                path: "/qaList",
                name: "qaList",
                component: () =>
                    import("@/views/vdcCourse/course/qa_list.vue"),
                meta: {
                    title: "常见问题列表",
                },
            },
        ],
    },
    {
        path: "/checkTxt",
        name: "checkTxt",
        component: () =>
            import("@/views/vdcCourse/course/check_txt.vue"),
        meta: {
            title: "说明文档",
        },
    },
    {
        path: "/vdcReport",
        name: "vdcReport",
        component: () =>
            import("@/views/vdcCourse/report/layout_report.vue"),
        meta: {
            title: "vdc报告",
        },
    },
    {
        path: "/mapPage",
        name: "mapPage",
        component: () => import("@/views/map_page.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/mapSandKjzh",
        name: "mapSandKjzh",
        component: () => import("@/views/vdcCourse/report/map_sand_KJZH.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/bookkeepingVDC",
        name: "bookkeepingVDC",
        component: () =>
            import("@/views/vdcCourse/report/book_keeping_vdc.vue"),
        meta: {
            title: "记账凭证",
        },
    },
    {
        path: "/vdcKSHmodel",
        name: "vdcKSHmodel",
        component: () =>
            import("@/views/vdcCourse/course/ksh_task_model.vue"),
        meta: {
            title: "任务列表",
        },
    },
    {
        path: "/vdcKSHtask",
        name: "vdcKSHtask",
        component: () =>
            import("@/views/vdcCourse/course/ksh_task_list.vue"),
        meta: {
            title: "任务列表",
        },
    },
    {
        path: "/mapSandAI",
        name: "mapSandAI",
        component: () => import("@/views/vdcCourse/report/map_sand_ai.vue"),
        meta: {
            title: "",
        },
    },

    // 财经百校
    {
        // 布局
        path: "/reportList",
        name: "reportList",
        component: () => import("@/views/layout.vue"),
        // redirect: "/reportList/index",
        redirect: "/reportList_index",
        meta: {
            title: "财经百校报告",
        },
        children: [
            {
                path: "/reportList_index",
                name: "reportList_index",
                component: () => import("@/views/reportList/index.vue"),
                meta: {
                    title: "财经百校报告",
                },
            },
            {
                path: "/reportList_list",
                name: "reportList_list",
                component: () => import("@/views/reportList/list.vue"),
                meta: {
                    title: "报告列表",
                },
            },
            {
                path: "/reportList_details",
                name: "reportList_details",
                component: () => import("@/views/reportList/details.vue"),
                meta: {
                    title: "报告详情",
                },
            },
        ],
    },
    // 案例研讨版进校内报告
    {
        path: "/caseReport_details",
        name: "caseReport_details",
        component: () => import("@/views/reportList/details.vue"),
        meta: {
            title: "",
        },
    },

     // 案例事实库
    //  {
    //     path: "/caseFact",
    //     name: "caseFact",
    //     component: () => import("@/views/layout.vue"),
    //     redirect: "/caseFactIndex",
    //     meta: {
    //         title: "案例事实库",
    //     },
    //     children: [
    //         {
    //             path: "/caseFactIndex",
    //             name: "caseFactIndex",
    //             component: () => import("@/views/caseFact/index.vue"),
    //             meta: {
    //                 title: "案例事实库",
    //             },
    //         },
            
    //     ]
    //  },
     {
        path: '/case_info',
        name: 'case_info',
        component: () =>
            import('@/views/caseFact/info.vue'),
        meta: {
            title: '信息卡片',
        }
    },


    

    // 论文选题
    {
        path: "/selTopic",
        name: "selTopic",
        component: () => import("@/views/layout.vue"),
        redirect: "/selected_topic",
        meta: {
            title: "论文选题",
        },
        children:[
            {  
                path: '/selected_topic',
                name: 'selected_topic',
                component: () =>
                    import('@/views/selected_topic/index.vue'),
                meta: {
                    title: '论文选题',
                },
            },
            {
                path: '/topic_directional',
                name: 'topic_directional',
                component: () =>
                    import('@/views/selected_topic/topic_directional.vue'),
                meta: {
                    title: '定向选题',
                }
            },
            {
                path: '/topic_filter_ProblemSpace',
                name: 'topic_filter_ProblemSpace',
                component: () =>
                    import('@/views/selected_topic/topic_filter_ProblemSpace.vue'),
                meta: {
                    title: '问题优先',
                }
            },
            {
                path: '/topic_filter',
                name: 'topic_filter',
                component: () =>
                    import('@/views/selected_topic/topic_filter.vue'),
                meta: {
                    title: '事实优先',
                }
            },
            {
                path: '/topic_innovate',
                name: 'topic_innovate',
                component: () =>
                    import('@/views/selected_topic/topic_innovate.vue'),
                meta: {
                    title: '创新选题',
                }
            },
            {
                path: '/topic_intelligence',
                name: 'topic_intelligence',
                component: () =>
                    import('@/views/selected_topic/topic_intelligence.vue'),
                meta: {
                    title: '智能选题',
                }
            },
            {
                path: '/topic_random',
                name: 'topic_random',
                component: () =>
                    import('@/views/selected_topic/topic_random.vue'),
                meta: {
                    title: '随机选题',
                }
            },
            {
                path: '/intelligent_filter',
                name: 'intelligent_filter',
                component: () =>
                    import('@/views/caseFact/intelligent_filter.vue'),
                meta: {
                    title: '智能筛选',
                }
            },
            {
                path: '/opening_report',
                name: 'opening_report',
                component: () =>
                    import('@/views/caseFact/opening_report.vue'),
                meta: {
                    title: '技术路线图',
                }
            },
            {
                path: '/research_path',
                name: 'research_path',
                component: () =>
                    import('@/views/caseFact/research_path.vue'),
                meta: {
                    title: '技术路线图',
                }
            },
            {
                path: '/case_topics',
                name: 'case_topics',
                component: () =>
                    import('@/views/caseFact/topics.vue'),
                meta: {
                    title: '选题评价',
                }
            },
            {
                path: '/case_firm',
                name: 'case_firm',
                component: () =>
                    import('@/views/caseFact/firm.vue'),
                meta: {
                    title: '描述统计',
                }
            },
            {
                path: '/case_sequence',
                name: 'case_sequence',
                component: () =>
                    import('@/views/caseFact/sequence.vue'),
                meta: {
                    title: '事件序列',
                }
            },
            {
                path: '/case_detail_list',
                name: 'case_detail_list',
                component: () =>
                    import('@/views/caseFact/detail_list.vue'),
                meta: {
                    title: '案例事实库',
                }
            },
            {
                path: '/case_event',
                name: 'case_event',
                component: () =>
                    import('@/views/caseFact/event.vue'),
                meta: {
                    title: '案例事实描述统计分析',
                }
            },
            {
                path: '/case_senior_list',
                name: 'case_senior_list',
                component: () =>
                    import('@/views/caseFact/senior_list.vue'),
                meta: {
                    title: '高级检索',
                }
            },

            {
                path: '/scheme_preset',
                name: 'scheme_preset',
                component: () =>
                    import('@/views/caseFact/scheme_preset.vue'),
                meta: {
                    title: '方案预设',
                }
            },
        ]
    },

    // 文本分析
    // {
    //     path: "/txtRetrieval",
    //     name: "txtRetrieval",
    //     component: () => import("@/views/layout.vue"),
    //     redirect: "/txtRetrievalIndex",
    //     meta: {
    //         title: "文本分析",
    //     },
    //     children:[
    //         {
    //             path: "/txtRetrievalIndex",
    //             name: "txtRetrievalIndex",
    //             component: () =>
    //                 import("@/views/txtRetrieval/index.vue"),
    //             meta: {
    //                 title: "文本分析",
    //             },
    //         },
    //         {
    //             path: "/noticeRetrieval",
    //             name: "noticeRetrieval",
    //             component: () =>
    //                 import("@/views/txtRetrieval/notice.vue"),
    //             meta: {
    //                 title: "公告检索",
    //             },
    //         },
    //         {
    //             path: "/operateRetrieval",
    //             name: "operateRetrieval",
    //             component: () =>
    //                 import("@/views/txtRetrieval/operate.vue"),
    //             meta: {
    //                 title: "经营检索",
    //             },
    //         },

    //         {
    //             path: "/accountant",
    //             name: "accountant",
    //             component: () =>
    //                 import("@/views/txtRetrieval/operate.vue"),
    //             meta: {
    //                 title: "会计检索",
    //             },
    //         },
    //         {
    //             path: "/supervise",
    //             name: "supervise",
    //             component: () =>
    //                 import("@/views/txtRetrieval/operate.vue"),
    //             meta: {
    //                 title: "监管检索",
    //             },
    //         },
    //         {
    //             path: "/government",
    //             name: "government",
    //             component: () =>
    //                 import("@/views/txtRetrieval/government.vue"),
    //             meta: {
    //                 title: "政务检索",
    //             },
    //         },
    //     ]
    // },

     
    // 教学案例
    {
        // 布局
        path: "/teachCase",
        name: "teachCase",
        component: () => import("@/views/layout.vue"),
        // redirect: "/reportList/index",
        redirect: "/teachCase_index",
        meta: {
            title: "教学案例库",
        },
        children: [
            {
                path: "/teachCase_index",
                name: "teachCase_index",
                component: () => import("@/views/teachCase/index.vue"),
                meta: {
                    title: "教学案例库",
                },
            },
            {
                path: "/teachCase_my_list",
                name: "teachCase_my_list",
                component: () => import("@/views/teachCase/my_list.vue"),
                meta: {
                    title: "我的案例库",
                },
            },
            // {
            //     path: "/reportList_list",
            //     name: "reportList_list",
            //     component: () => import("@/views/reportList/list.vue"),
            //     meta: {
            //         title: "报告列表",
            //     },
            // },
        ],
    },
    {
        path: "/teachCase_detail",
        name: "teachCase_detail",
        component: () => import("@/views/teachCase/detail.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/teachCase_detail_phone",
        name: "teachCase_detail_phone",
        component: () => import("@/views/teachCase/detail_phone.vue"),
        meta: {
            title: "",
        },
    },

   
    
    {
        path: "/pageofice6",
        name: "pageofice6",
        component: () => import("@/views/pageofice6.vue"),
    },
    {
        path: "/test",
        name: "test",
        component: () => import("@/views/test/test.vue"),
    },
    {
        path: "/ceds",
        name: "ceds",
        component: () => import("@/views/test/cedsFile.vue"),
    },
    {
        path: "/case",
        name: "case",
        component: () => import("@/views/test/caseDetail.vue"),
    },
    {
        path: "/svg",
        name: "svg",
        component: () => import("@/views/test/svg.vue"),
    },

    {
        path: "/jingyingjihua",
        name: "jingyingjihua",
        component: () => import("@/views/jingying.vue"),
    },

    


    // // 教学案例生成器
    // {
    //     path: "/caseCloud",
    //     name: "caseCloud",
    //     component: () => import("@/views/layout.vue"),
    //     redirect: "/caseCloud/index",
    //     meta: {
    //         title: "教学案例生成器(内测版)",
    //     },
    //     children: [
    //         // supportingDocumentation
    //         {
    //             path: "/caseCloud/index",
    //             name: "caseCloud_index",
    //             component: () => import("@/views/caseCloud/index.vue"),
    //             meta: {
    //                 title: "教学案例生成器(内测版)",
    //             },
    //         },
    //     ]
    // },
    // {
    //     path: "/caseCloud/info",
    //     name: "info",
    //     component: () => import("@/views/caseCloud/info.vue"),
    //     meta: {
    //         title: "信息卡片",
    //     },
    // },
    

    /*{
      //常用写法,通过正则表达式,如果路由不匹配去这个404组件
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../view/home/Home.jsx')
    },*/
    
];
